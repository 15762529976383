<template>
    <div v-if="value">
        <div class="model-box">
            <div class="title">栏目建设情况</div>
            <div class="explain">本社区目前上线的一级栏目有:</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="first_column" :dataList="value.firstColumnList"></ElemTable>
            </div>
        </div>
        <div class="model-box" v-if="value.onlineStatus == 1">
            <div class="title">空白栏目清单</div>
            <div class="explain" style="color:red" v-if="blankColumns && blankColumns.length > 0">温馨提醒：根据市民政局要求，请社区（村）领导安排有关工作人员尽快进行空白栏目内容的补充发布！谢谢合作。</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="blank_column" :dataList="blankColumns"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">栏目应用情况</div>
            <div class="explain">栏目信息发布数:</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="publish_column" :dataList="value.columnActionList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="explain">居民接受服务人次:</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="serve_column" :dataList="value.columnActionList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="explain">参与活动人次:</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="activity_column" :dataList="value.columnActionList"></ElemTable>
            </div>
        </div>
    </div>
</template>

<script>
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: { ElemTable },

    data() {
        return {
            // 空白栏目清单
            blankColumns: null,
            // 一级栏目
            first_column: [
                {
                    title: "栏目名称",
                    key: "name",
                },
            ],
            // 信息发布
            publish_column: [
                {
                    title: "栏目名称",
                    key: "columnName",
                    width: 300,
                },
                {
                    title: "本期新增",
                    key: "columnAddPublishNum",
                    highlight: true,
                    width: 100,
                },
                {
                    title: "累计信息发布数",
                    highlight: true,
                    sort: "desc",
                    width: 200,
                    render: (h, v) => v.row.columnPublishNum || `<p style="color:red">空白栏目</p>`,
                },
            ],
            // 空白栏目
            blank_column: [
                {
                    title: "栏目名称",
                    key: "columnName",
                    width: 300,
                },
            ],
            // 服务人次
            serve_column: [
                {
                    title: "栏目名称",
                    key: "columnName",
                    width: 300,
                },
                {
                    title: "本期新增",
                    key: "columnAddViewNum",
                    highlight: true,
                    width: 100,
                },
                {
                    title: "累计接受服务人次",
                    key: "columnViewNum",
                    highlight: true,
                    sort: "desc",
                    width: 200,
                },
            ],
            // 活动人次
            activity_column: [
                {
                    title: "栏目名称",
                    key: "columnName",
                    width: 300,
                },
                {
                    title: "本期新增",
                    key: "columnAddJoinNum",
                    highlight: true,
                    width: 100,
                },
                {
                    title: "累计参与活动人次",
                    key: "columnJoinNum",
                    highlight: true,
                    sort: "desc",
                    width: 200,
                },
            ],
        }
    },

    props: {
        value: Object,
        expand: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(val) {
                if (!val || Object.keys(val).length === 0) return

                const list = []

                val.columnActionList.forEach(item => {
                    if (!item.columnPublishNum) {
                        list.push(item)
                    }
                })

                this.blankColumns = list
            },
            immediate: true,
        },
    },
}
</script>

<style></style>
